<template>
  <div class="featured-products container">
    <h1 class="featured-products-title">PRODUTOS EM DESTAQUE</h1>

    <div class="featured-products-list">
      <div class="featured-products-list-item" v-for="product in products" :key="product.id">
        <div>
          <img :src="product.image" :alt="product.desc" class="featured-products-list-item-img">
          <p class="featured-products-list-item-desc">{{ product.desc }}</p>
          <p class="featured-products-list-item-price">{{ product.price }}</p>
        </div>
        <button class="featured-products-list-item-button btn"
                @click="this.$router.push({ name: 'product', params: { product: product.slug }})"
        >Ver mais</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "FeaturedProducts",
  data() {
    return {
      products: []
    }
  },
  created() {
    this.$store.commit('incrementRequests');
    fetch(`${process.env.VUE_APP_API_URL}/products`, {
      method: 'GET',
      headers: {"Authorization": `Bearer ${process.env.VUE_APP_API_TOKEN}`}
    }).then(response => response.json())
        .then((result) => {
          this.products = [];
          if (result.products) {
            result.products.forEach((element) => {
              this.products.push({
                image: element.image,
                desc: element.title,
                price: `R$ ${element.price}`,
                slug: element.slug
              })
            });
          }
          this.$store.commit('decrementRequests');
        })
        .catch(error => {
          this.$store.commit('decrementRequests');
          console.log('error', error)
        });
  }
}
</script>

<style scoped>
.featured-products {
  background-color: var(--theme_color_light);
  text-align: center;
}

.featured-products-title {
  display: inline-block;
  margin-bottom: 10px;
  color: var(--theme_color_1);
  font-weight: normal;
  border-bottom: 5px solid var(--theme_color_1);
}

.featured-products-desc{
  display: block;
  width: 100%;
  padding: 10px 50px;
  color: var(--theme_text_light);
  text-transform: uppercase;
  font-size: 16px;
}

.featured-products-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 0;
}

.featured-products-list-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-basis: 49%;
  margin-bottom: 2%;
  margin-right: 1%;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #ffffff;
}

.featured-products-list-item img{
  margin-bottom: 10px;
}

.featured-products-list-item-desc{
  color: var(--theme_color_1);
  margin-bottom: 10px;
}

.featured-products-list-item-price{
  color: var(--theme_color_1);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .featured-products-list-item{
    flex-basis: 24%;
    margin-right: 1%;
  }
}
</style>
