<template>
  <div class="slider">
    <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index"
                     :visibleSlide="visibleSlide" :direction="direction"
                     :style="{'background-image': 'url('+slide.image+')', cursor: 'pointer'}"
                     @click="$router.push({name: 'product', params: {product: slide.slug}})"
      >
      </CarouselSlide>
    </Carousel>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import CarouselSlide from "@/components/CarouselSlide";

export default {
  name: "Slider",
  components: {CarouselSlide, Carousel},

  props: {
    slider_items: Array,
    slider_count: Number
  },

  data() {
    return {
      slides: [],
      visibleSlide: 0,
      direction: 'left'
    }
  },

  computed: {
    slidesLen() {
      return this.slides.length;
    }
  },

  methods: {
    next() {
      if (this.visibleSlide >= this.slidesLen - 1) {
        this.visibleSlide = 0;
      } else {
        this.visibleSlide++;
      }
      this.direction = 'left';
    },
    prev() {
      if (this.visibleSlide <= 0) {
        this.visibleSlide = this.slidesLen - 1;
      } else {
        this.visibleSlide--;
      }
      this.direction = 'right';
    },
  },

  created() {
    setInterval(this.next, 5000);
    this.$store.commit('incrementRequests');
    fetch(`${process.env.VUE_APP_API_URL}/products/banners`, {
      method: "GET",
      headers: {"Authorization": `Bearer ${process.env.VUE_APP_API_TOKEN}`}
    }).then(result => result.json())
        .then((result) => {
          this.slides = [];
          if (result.products) {
            result.products.map((product) => {
              this.slides.push({
                image: product.image,
                slug: product.slug
              }
              );
            })
          }
          this.$store.commit('decrementRequests');
        })
        .catch((error) => {
          this.$store.commit('decrementRequests');
          console.log(error)
        });
  }
}
</script>

<style scoped>
.slider {
  flex-basis: 100%;
}

.slider-nav {
  text-align: center;
}


@media (min-width: 768px) {
  .slider {
    flex-basis: 74%;
  }
}
</style>
