<template>
  <div class="container">
    <div class="product">
      <div class="product-cover">
        <img :src="product.image" :alt="product.title">
      </div>

      <div class="product-info">
        <h1 class="product-title">{{ product.title }}</h1>
        <span class="product-price">R$ {{ product.price }}</span>
        <hr>
        <p class="product-description">{{ product.description }}</p>
        <div class="add-to-cart">
          <button class="btn" @click="$store.commit('addProduct', product)">Adicionar ao carrinho</button>
        </div>
      </div>

      <div class="product-features">
        <h1 class="product-features-title">Informação adicional</h1>
        <table>
          <tr class="product-features-item" v-for="(feature, index) in product.features" :key="index">
            <td>{{ feature.title }}</td>
            <td>{{ feature.content }}</td>
          </tr>
        </table>
      </div>
    </div>
    <ProductsFooter/>
  </div>
</template>

<script>
import ProductsFooter from "@/components/ProductsFooter";

export default {
  name: "Product",
  components: {ProductsFooter},

  data() {
    return {
      product: {}
    }
  },
  created() {
    this.getProduct();
    this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          if (this.$route.params.product) {
            this.getProduct();
          }
        }
    );
  },
  methods: {
    getProduct() {
      fetch(`${process.env.VUE_APP_API_URL}/product/${this.$route.params.product}`, {
        method: "GET",
        headers: {"Authorization": `Bearer ${process.env.VUE_APP_API_TOKEN}`}
      }).then(result => result.json())
          .then((result) => {
            this.product = result.product;
          })
          .catch(error => console.log(error));
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
}

.product {
  display: flex;
  flex-wrap: wrap;
}

.product-cover {
  flex-basis: 100%;
  padding: 10px;
  border: 1px solid var(--theme_color_light);
  margin-bottom: 20px;
}

.product-info {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.product-title {
  font-size: 26px;
  font-weight: bold;
  color: var(--theme_color_1);
}

.product-price {
  font-size: 22px;
  font-weight: bold;
  color: var(--theme_color_2);
}

hr {
  border: 1px solid var(--theme_color_light);
  margin: 10px 0;
}

.product-description {
  color: var(--theme_color_3);
  margin-bottom: 20px;
}

.add-to-cart {
  width: 100%;
  display: flex;
}

.product-features {
  margin-top: 40px;
  flex-basis: 100%;
}

.product-features-title {
  font-size: 22px;
  color: var(--theme_color_1);
  margin-bottom: 20px;
}

.product-features table {
  width: 100%;
  border-collapse: collapse;
}

.product-features-item {
  width: 100%;
  padding: 10px;
  border-top: 1px solid var(--theme_color_light);
}

.product-features-item:last-child {
  border-bottom: 1px solid var(--theme_color_light);
}

.product-features-item:nth-child(odd) {
  background-color: var(--theme_color_light);
}

.product-features-item td {
  padding: 5px;
  width: 50%;
}

@media (min-width: 768px) {
  .product-cover {
    flex-basis: 49%;
    margin-right: 1%;
  }

  .product-info {
    flex-basis: 49%;
    margin-left: 1%;
  }
}

@media (min-width: 1024px) {
  .product {
    flex-basis: 70%;
  }
}
</style>
