<template>
  <div class="content">
    <h1 class="content-title">Minhas compras</h1>

    <div class="content-search">
      <input type="search" class="content-search-input" placeholder="Pesquisar">
      <button class="btn"><i class="fa fa-search"></i></button>
    </div>

    <div class="content-items">
      <div class="content-item" v-for="(order, index) in 10" :key="index">
        <div class="content-item-title">21 de dezembro de 2022</div>
        <div class="content-item-body">
          <img src="https://caccontrol.dev.br/storage/images/2022/09/pistola-taurus-380-preta.jpg" alt="produto">
          <div class="description">
            <span class="status">Entregue</span>
            <span class="message">Produto entregue ao destinatário</span>
            <span class="product-name">Pistola glock 9mm preto fosco</span>
          </div>
          <div style="display: flex; align-items: center">
            <button class="btn" @click="$router.push({name: 'client-area-order', params: {order: order}})">Ver detalhes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientAreaMain"
}
</script>

<style scoped>
.content-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content-search-input {
  flex-grow: 1;
  padding: 5px;
}

.content-items, .content-item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-item {
  border: 1px solid var(--theme_color_1);
  padding: 10px;
  margin: 10px 0;
}

.content-item-title {
  border-bottom: 1px solid var(--theme_color_1);
  padding: 10px;
}

.content-item-body {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
  justify-content: center;
}

.content-item-body img {
  width: 100px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 2px;
}

.content-item-body .description {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  flex-grow: 1;
  justify-content: start;
}

.content-item-body .description .status {
  font-weight: bold;
  margin-bottom: 10px;
}

.content-item-body .description .message {
  margin-bottom: 10px;
}

.content-item-body .description .product-name {
  margin-bottom: 10px;
  color: var(--theme_text_light);
}
</style>
