<template>
  <div class="carousel">
    <slot></slot>
    <button class="prev" @click="prev"><i class="fas fa-arrow-left"></i></button>
    <button class="next" @click="next"><i class="fas fa-arrow-right"></i></button>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  methods: {
    next(){
      this.$emit('next');
    },
    prev(){
      this.$emit('prev');
    }
  }
}
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
}
button{
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  background-color: #000000aa;
  border: none;
  color: #ffffff;
}
button:focus, button:hover{
  outline: none;
  cursor: pointer;
}
.next{
  right: 0;
}
.prev{
  left: 0;
}

</style>
