<template>
  <div class="container">
    <ProductList/>
    <ProductsFooter/>
  </div>
</template>

<script>
import ProductList from "@/components/ProductList";
import ProductsFooter from "@/components/ProductsFooter";

export default {
  name: "Products",
  components: {ProductsFooter, ProductList}
}
</script>

<style scoped>
.container{
  background-color: var(--theme_color_light);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
</style>
