<template>
  <div class="main-header">
    <div v-show="$store.state.pendingRequests !== 0" class="loading">
      <img src="@/assets/loading.gif" alt="Carregando...">
    </div>

    <div class="header container">
      <button class="toggle-menu" v-on:click="openMenu"><i class="fas fa-bars"></i></button>
      <div class="main-header-logo" @click="$router.push('/')">
        <img src="@/assets/logo.png" alt="Clube de Tiro da Mata">
        <h1>Clube de Tiro da Mata</h1>
      </div>
      <div>
        <div class="mobile-hide">
          <div class="contact">
            <span class="icon"><i class="fas fa-phone"></i></span>
            <span>(61) 99952-4179</span>
            <span>clubedetirodamata@gmail.com</span>
          </div>
        </div>
        <div class="cart">
          <span class="icon" @click="openCart"><i class="fas fa-shopping-cart"></i></span>
          <span>Ver Carrinho</span>
          <span>{{$store.state.cart.products.length}} itens | R$ {{ $store.state.cart.subtotal }}</span>
          <div class="float-cart" v-show="cartActive">
            <div class="close-cart"><i class="fas fa-times" @click="closeCart"></i></div>
            <div class="float-cart-item" v-for="product in $store.state.cart.products" :key="product.id"
                 v-if="$store.state.cart.products.length">
              <p class="float-cart-item-desc">{{ product.description }}</p>
              <i class="fas fa-times float-cart-item-remove"
                 @click="$store.commit('removeProduct', product.id)"></i>
              <p class="float-cart-item-qtd">{{ product.quantity }} x </p>
              <p class="float-cart-item-price">R$ {{ product.price }}</p>
            </div>
            <div class="float-cart-item" v-if="!$store.state.cart.products.length">
              <p class="float-cart-item-desc">Carrinho vazio</p>
            </div>
            <div class="float-cart-total">
              <p>SUBTOTAL: </p>
              <p>R$ {{ $store.state.cart.subtotal }}</p>
            </div>

            <div class="float-cart-buttons">
              <button class="btn" @click="$router.push({name: 'cart'})">VER CARRINHO</button>
              <button class="btn" @click="$router.push({name: 'checkout'})">FINALIZAR PEDIDO</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav class="main-nav" :class="{active:menuActive}">
      <ul>
        <li @click="closeMenu">
          <router-link to="/" :class="linkActive(['home'])">Home</router-link>
        </li>
        <li @click="closeMenu">
          <router-link to="/produtos" :class="linkActive(['products','product'])">Produtos</router-link>
        </li>
      </ul>
    </nav>
    <div id="navbar-overlay" v-on:click="closeMenu" v-if="menuActive">

    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      menuActive: false,
      cartActive: false
    }
  },
  methods: {
    openMenu() {
      this.menuActive = true;
    },
    closeMenu() {
      this.menuActive = false;
    },
    openCart() {
      this.cartActive = true;
    },
    closeCart() {
      this.cartActive = false;
    },
    linkActive(names) {
      const route = this.$router.currentRoute.value.name;
      if (route && names && names.includes(route)) {
        return 'active'
      }
      return '';
    }
  }
}
</script>

<style scoped>
.loading {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: #000000AA;
  z-index: 1001;
}

.loading img{
  width: 100px;
}

.main-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: var(--theme_color_1);
}

.main-header .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
}

.toggle-menu {
  background: none;
  width: 25px;
  height: 25px;
  font-size: 25px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.main-header-logo img {
  max-width: 100px;
  cursor: pointer;
}

.main-header-logo h1 {
  font-size: 0;
}

.mobile-hide {
  display: none;
}

.mobile-hide span,
.cart span {
  display: none;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
}

.cart {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}

.float-cart {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  right: 0;
  background: var(--theme_color_3);
  padding: 20px;
  width: 300px;
}

.close-cart {
  flex-basis: 100%;
  color: red;
  text-align: right;
  margin-bottom: 20px;
}

.close-cart i {
  font-size: 25px;
  cursor: pointer;
}

.float-cart-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.float-cart-item-remove {
  flex-basis: 5%;
  color: var(--theme_color_2);
  font-size: 12px;
}

.float-cart-item-desc {
  flex-basis: 95%;
  color: #ffffff;
  margin-bottom: 5px;
}

.float-cart-item-qtd {
  flex-basis: 10%;
  margin-left: 5%;
  color: var(--theme_text_light)
}

.float-cart-item-price {
  flex-basis: 85%;
  color: var(--theme_text_light);
  padding-left: 5px;
}

.float-cart-total {
  color: #ffffff;
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px 0;
}

.float-cart-buttons {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
}

.float-cart-buttons .btn {
  flex-basis: 49%;
  background: var(--theme_color_2);
}

.float-cart-buttons .btn:hover {
  background: var(--theme_color_3);
}

span.icon {
  font-size: 25px;
  display: block;
}

.main-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60%;
  background: var(--theme_color_1);
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  border-top: 2px solid var(--theme_color_3);
  z-index: 1000;
}

.main-nav.active {
  display: flex;
}

.main-nav ul {
  list-style: none;
  flex-basis: 100%;
}

.main-nav ul li {
  border-bottom: 1px solid var(--theme_color_3);
}

.main-nav a {
  display: block;
  padding: 20px 30px;
  width: 100%;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
}

.main-nav a.active {
  color: var(--theme_color_2);
}

.main-nav a:hover {
  background: var(--theme_color_3);
  transition: 0.2s;
}

#navbar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40%;
  background: #000000aa;
}

.nav-search {
  height: 25px;
  margin: 15px;
  border: none;
  padding: 10px;
  background: var(--theme_color_3);
  color: #ffffff;
  outline: none;
}

.nav-search-btn {
  width: 25px;
  height: 25px;
  padding: 5px;
}

@media (min-width: 1024px) {
  .mobile-hide {
    display: inline-block;
  }

  .mobile-hide span,
  .cart span {
    display: block;
  }

  .main-nav {
    display: flex;
    position: initial;
    padding: 0 5%;
    width: 100%;
    z-index: auto;
  }

  .main-nav ul {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    padding: 0;
  }

  .main-nav ul li {
    border: none;
  }

  .main-nav ul a {
    padding: 20px 30px;
  }

  #navbar-overlay {
    display: none;
  }

  .toggle-menu {
    display: none;
  }
}
</style>
