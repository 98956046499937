<template>
  <div class="content">
    <h1 class="content-title">Detalhes da compra</h1>

    <div class="product-description">
      <img
          src="https://caccontrol.dev.br/storage/images/2022/09/pistola-taurus-380-preta.jpg"
          alt="">
      <div>
        <span class="product-name">Pistola glock 9mm preto fosco</span>
        <span class="product-qtd">1 unidade</span>
      </div>
    </div>

    <div class="detail-items">
      <div class="detail-item">
        <span>Data</span>
        <span>21/12/2022</span>
      </div>
      <div class="detail-item">
        <span>Produto</span>
        <span>R$ 2222,00</span>
      </div>
      <div class="detail-item">
        <span>Frete</span>
        <span>R$ 0,00</span>
      </div>
      <div class="detail-item">
        <span>Total</span>
        <span>R$ 0,00</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderDetail"
}
</script>

<style scoped>
.product-description {
  width: 600px;
  max-width: 100%;
  display: flex;
  padding: 5px;
  background-color: #eeeeee;
  border-radius: 5px;
}

.product-description img{
  width: 100px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 2px;
  margin-right: 5px;
}

.product-description div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.detail-items {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 600px;
  max-width: 100%;
  background-color: #eeeeee;
  padding: 10px;
}

.detail-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.detail-item:not(:last-child) {
  border-bottom: 1px solid #cccccc;
}
</style>
