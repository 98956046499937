import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Products from "@/views/Products";
import Offers from "@/components/Offers";
import Product from "@/views/Product";
import ClientArea from "@/views/ClientArea";
import Cart from "@/views/Cart";
import Checkout from "@/views/Checkout";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/produtos/:category',
    name: 'category',
    component: Products
  },
  {
    path: '/produtos',
    name: 'products',
    component: Products
  },
  {
    path: '/produto/:product',
    name: 'product',
    component: Product
  },
  {
    path: '/outras-ofertas',
    name: 'offers',
    component: Offers
  },
  {
    path: '/carrinho',
    name: 'cart',
    component: Cart
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/area-do-cliente',
    name: 'client-area',
    component: ClientArea
  },
  {
    path: '/area-do-cliente/compra/:order',
    name: 'client-area-order',
    component: ClientArea
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
