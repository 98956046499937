<template>
  <Header/>
  <router-view/>
  <FooterWrap/>
  <Footer/>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import FooterWrap from "@/components/FooterWrap";
export default {
  components: {FooterWrap, Footer, Header}
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

img {
  width: 100%;
}

:root {
  --theme_color_1: #1D1E23;
  --theme_color_2: #DE1D70;
  --theme_color_3: #121215;
  --theme_color_light: #F4F4F4;
  --theme_text_light: #808080;
}

.container {
  padding: 30px 5%;
}

.btn {
  display: inline-block;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: var(--theme_color_1);
  color: #ffffff;
  border: 1px solid var(--theme_color_3);
  cursor: pointer;
}

.btn:hover {
  background-color: var(--theme_color_3);
  color: var(--theme_color_2);
  border-color: var(--theme_color_2);
  transition: 0.2s;
}
</style>
