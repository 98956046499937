<template>
  <div class="pager" v-if="totalPages > 1">
    <ul class="pagination">
      <li class="pagination-item">
        <button @click="onClickFirstPage" :disabled="isInFirstPage"><i class="fas fa-caret-left"></i><i class="fas fa-caret-left"></i></button>
      </li>
      <li class="pagination-item">
        <button @click="onClickPreviousPage" :disabled="isInFirstPage"><i class="fas fa-caret-left"></i></button>
      </li>
      <!-- Visible buttons start -->
      <li v-for="page in pages" :key="page.name" class="pagination-item">
        <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled"
                :class="{ active: isPageActive(page.name) }">
          {{ page.name }}
        </button>
      </li>
      <!-- Visible buttons end -->
      <li class="pagination-item">
        <button @click="onClickNextPage" :disabled="isInLastPage"><i class="fas fa-caret-right"></i></button>
      </li>
      <li class="pagination-item">
        <button @click="onClickLastPage" :disabled="isInLastPage"><i class="fas fa-caret-right"></i><i class="fas fa-caret-right"></i></button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pager",
  props: {
    range: {
      type: Number,
      required: false,
      default: 2
    },
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage < 2) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        if ((this.totalPages - this.maxVisibleButtons) <= 1) {
          return 1;
        }
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return 1;
    },
    pages() {
      const range = [];

      //pages before
      for (let b = this.currentPage - this.range; b < this.currentPage; b++) {
        if (b >= 1) {
          range.push({
            name: b,
            isDisabled: false
          })
        }
      }
      range.push({
        name: this.currentPage,
        isDisabled: true
      });

      //pages after
      for (
          let a = this.currentPage + 1;
          a <= this.currentPage + this.range;
          a++
      ) {
        if (a <= this.totalPages) {
          range.push({
            name: a,
            isDisabled: false
          });
        }
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
      return parseInt(this.currentPage) === page;
    }
  }
}
</script>

<style scoped>
.pager{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}
.pagination-item button{
  cursor: pointer;
  background: var(--theme_color_1);
  border: none;
  padding: 5px 10px;
  margin: 2px;
  color: #ffffff;
}
.pagination-item button.active{
  background: var(--theme_color_2);
}

.active {
  background-color: #4AAE9B;
  color: #ffffff;
}
</style>
