<template>
  <div class="products-footer">
    <div class="footer-cart">
      <h1 class="footer-products-title">Carrinho</h1>
      <div class="cart-item" v-if="$store.state.cart.products.length"
           v-for="product in $store.state.cart.products" :key="product.id">
        <i class="fas fa-times cart-item-remove" @click="$store.commit('removeProduct', product.id)"></i>
        <p class="cart-item-desc">{{ product.description }}</p>
        <span class="cart-item-qtd">{{ product.quantity }} x </span>
        <span class="cart-item-price">R$ {{ product.price }}</span>
      </div>
      <div class="cart-item" v-if="!$store.state.cart.products.length">
        <p>Carrinho vazio</p>
      </div>
      <div class="cart-subtotal">
        <span>Subtotal: </span> <span>R$ {{ $store.state.cart.subtotal }}</span>
      </div>
      <div class="cart-buttons">
        <button class="btn" @click="$router.push({name: 'cart'})">VER CARRINHO</button>
        <button class="btn" @click="$router.push({name: 'checkout'})">FINALIZAR PEDIDO</button>
      </div>
    </div>
    <div class="footer-categories">
      <h1 class="footer-products-title">Categorias</h1>
      <ul class="footer-categories-list">
        <li class="footer-categories-list-item" v-for="(category, index) in footerCategories" :key="index">
          {{ category.title }}
          <ul class="footer-categories-sub-list" v-show="category.sub_categories">
            <li class="footer-categories-sub-list-item" v-for="(subcategory, index) in category.sub_categories"
                :key="index">
              <router-link :to="{ name: 'category', params: { category: subcategory.slug }}">{{
                  subcategory.title
                }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsFooter",
  data() {
    return {
      footerCategories: []
    }
  },
  created() {
    this.$store.commit('incrementRequests');
    fetch(`${process.env.VUE_APP_API_URL}/categories`, {
      method: "GET",
      headers: {"Authorization": process.env.VUE_APP_API_TOKEN}
    }).then(result => result.json())
        .then((result) => {
          this.footerCategories = [];
          result.categories.forEach((category) => {
            this.footerCategories.push(category);
          });
          this.$store.commit('decrementRequests');
        })
        .catch((error) => {
          this.$store.commit('decrementRequests');
          console.log(error)
        });
  }
}
</script>

<style scoped>
.products-footer {
  background: var(--theme_color_1);
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5%;
}


.footer-products-title {
  display: block;
  width: 100%;
  margin: 20px 0;
  font-size: 20px;
  text-transform: uppercase;
}

.footer-cart {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--theme_color_3);
}

.cart-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.cart-item-remove {
  flex-basis: 5%;
  color: var(--theme_color_2);
  font-size: 12px;
}

.cart-item-desc {
  flex-basis: 95%;
  color: var(--theme_text_light);
}

.cart-item-qtd {
  flex-basis: 10%;
  margin-left: 5%;
  color: var(--theme_text_light)
}

.cart-item-price {
  flex-basis: 85%;
  color: var(--theme_text_light);
  padding-left: 5px;
}

.cart-subtotal {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px 0;
}

.cart-buttons {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
}

.cart-buttons .btn {
  flex-basis: 49%;
  background: var(--theme_color_2);
}

.cart-buttons .btn:hover {
  background: var(--theme_color_3);
}

.footer-categories {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  border-bottom: 1px solid var(--theme_color_3);
}

.footer-categories-list {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding: 0 20px;
  color: var(--theme_text_light);
  list-style: none;
}

.footer-categories-list-item {
  padding: 3px 6px;
}

.footer-categories-list-item::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--theme_color_2); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.footer-categories-sub-list {
  list-style: none;
}

.footer-categories-sub-list-item {
  padding: 3px 6px;
}

.footer-categories-sub-list-item a {
  color: var(--theme_text_light);
  text-decoration: none;
}

.footer-categories-sub-list-item a:hover {
  color: var(--theme_color_2);
  text-decoration: underline;
}

.footer-filter {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.footer-filter label {
  display: block;
  width: 100%;
  padding: 10px 0;
  text-transform: uppercase;
}

.footer-filter .btn {
  width: 100px;
  margin-top: 20px;
  background: var(--theme_color_2);
}

.footer-filter .btn:hover {
  background: var(--theme_color_3);
}

.input-filter-price {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: var(--theme_color_3);
  outline: none;
}

.input-filter-price::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--theme_color_2);
  cursor: pointer;
  border-radius: 50%;
}

.input-filter-price::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

@media (min-width: 768px) {
  .footer-cart,
  .footer-categories,
  .footer-filter {
    flex-basis: 49%;
  }
}

@media (min-width: 1024px) {
  .products-footer {
    width: 28%;
    display: inline-block;
    margin-top: 0;
  }

  .footer-cart,
  .footer-categories,
  .footer-filter {
    flex-basis: 100%;
  }
}
</style>
