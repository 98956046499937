<template>
  <div class="main-banner container">
    <Categories/>
    <Slider/>
  </div>
</template>

<script>
import Categories from "@/components/Categories";
import Slider from "@/components/Slider";
export default {
  name: "Banner",
  components: {Slider, Categories}
}
</script>

<style scoped>
.main-banner{
  background: var(--theme_color_3);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
</style>
