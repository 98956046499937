<template>
  <div class="container">
    <div class="cart-page">
      <h1 class="cart-page-title">Carrinho</h1>
      <table class="products-list">
        <thead>
        <tr>
          <th></th>
          <th>Produto</th>
          <th>Preço</th>
          <th>Quantidade</th>
          <th>Subtotal</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="product in $store.state.cart.products" :key="product.id">
          <td><span @click="$store.commit('removeProduct', product.id)" class="remove-product">&times;</span></td>
          <td>{{ product.description }}</td>
          <td>{{ Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(product.price) }}</td>
          <td>{{ product.quantity }}</td>
          <td>{{
              Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(product.price * product.quantity)
            }}
          </td>
        </tr>
        </tbody>
      </table>

      <div class="cart-details">
        <div class="cart-detail-item">
          <h2>Subtotal</h2>
          <p>{{
              Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format($store.state.cart.subtotal)
            }}</p>
        </div>

        <div class="cart-detail-item">
          <h2>Descontos</h2>
          <p>{{ Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(0) }}</p>
        </div>

        <div class="cart-detail-item">
          <h2>Total</h2>
          <p>{{
              Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format($store.state.cart.subtotal)
            }}</p>
        </div>

        <button class="btn" @click="$router.push({name: 'checkout'})">FINALIZAR PEDIDO</button>
      </div>
    </div>
  </div>
</template>

<script>
import Products from "@/views/Products";
export default {
  name: "Cart",
  components: {Products}
}
</script>

<style scoped>
.cart-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.cart-page-title {
  font-size: 24px;
  color: var(--theme_color_1);
  margin-bottom: 20px;
}

.products-list {
  flex-basis: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.products-list thead {
  display: none;
}

.products-list tbody tr {
  border-bottom: 1px solid #ccc;
}

.products-list tbody tr:first-child {
  border-top: 1px solid #ccc;
}

.products-list .remove-product {
  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  border-radius: 20px;
  font-size: 20px;
  background-color: #cccccc;
  color: #333333;
}

.products-list td {
  display: block;
  text-align: center;
  padding: 5px;
}

.cart-details {
  flex-basis: 100%;
  padding: 20px;
  background-color: var(--theme_color_1);
  color: var(--theme_color_light);
}

.cart-detail-item {
  margin: 20px 0;
  padding-bottom: 20px;
}

.cart-detail-item:not(:last-child) {
  border-bottom: 1px solid #555555;
}

.cart-detail-item h2 {
  margin-bottom: 10px;
}

.cart-details .btn {
  background-color: var(--theme_color_2);
  width: 100%;
}

.cart-details .btn:hover {
  background-color: var(--theme_color_1);
}

@media (min-width: 768px) {
  .cart-page-title{
    flex-basis: 100%;
  }

  .products-list {
    flex-basis: 68%;
  }

  .cart-details {
    flex-basis: 30%;
  }
}

@media (min-width: 1024px) {
  .products-list {
    display: flex;
    flex-direction: column;
  }

  .products-list thead{
    display: initial;
  }

  .products-list tr{
    display: flex;
  }

  .products-list :where(th, td) {
    padding: 5px;
  }

  .products-list :where(th, td):nth-child(1) {
    flex-basis: 6%;
    text-align: center;
  }
  .products-list :where(th, td):nth-child(2) {
    flex-basis: 50%;
  }
  .products-list :where(th, td):nth-child(3) {
    flex-basis: 15%;
  }
  .products-list :where(th, td):nth-child(4) {
    flex-basis: 15%;
  }
  .products-list :where(th, td):nth-child(5) {
    flex-basis: 15%;
  }
}
</style>
