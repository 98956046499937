<template>
  <div class="categories">
    <ul>
      <li class="category-title">CATEGORIAS</li>
      <li v-for="category in categories" :key="category.slug">
        <router-link :to="{ name: 'category', params: { category: category.slug }}">{{ category.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Categories",
  data() {
    return {
      categories: []
    }
  },
  created() {
    this.$store.commit('incrementRequests');
    fetch(`${process.env.VUE_APP_API_URL}/sub-categories`, {
        method: 'GET',
        headers: {"Authorization": `Bearer ${process.env.VUE_APP_API_TOKEN}`}
      }
    ).then(result => result.json())
      .then((result) => {
        this.categories = [];
        result.categories.forEach((category) => {
          this.categories.push(category);
        })

        this.$store.commit('decrementRequests');
      })
      .catch(error => {
        this.$store.commit('decrementRequests');
      });
  }
}
</script>

<style scoped>
.categories {
  flex-basis: 100%;
  background: var(--theme_color_1);
  margin-bottom: 15px;
}

ul {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.category-title {
  padding: 10px 30px;
  background: var(--theme_color_2);
  color: #ffffff;
  font-size: 18px;
}

li a {
  display: block;
  padding: 10px 30px;
  width: 100%;
  border-bottom: 1px solid #000000aa;
  color: #ffffff;
  text-decoration: none;
}

li a:hover {
  color: var(--theme_color_2);
  transition: 0.2s;
}

@media (min-width: 768px) {
  .categories {
    flex-basis: 25%;
  }
}
</style>
