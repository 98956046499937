<template>
  <div class="home">
    <Banner/>
    <FeaturedProducts/>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import FeaturedProducts from "@/components/FeaturedProducts";
export default {
  name: 'HomeView',
  components: {
    FeaturedProducts,
    Banner
  }
}
</script>
