<template>
  <div class="container">
    <div class="buyer-area">
      <div class="sidebar">
        <ul>
          <li>
            <i class="fas fa-list"></i>
          </li>
          <li @click="$router.push({name: 'client-area'})">
            <i class="fas fa-home"></i>
            <span class="sidebar-description"> Minhas Compras</span>
          </li>
          <li>
            <i class="fas fa-paperclip"></i>
            <span class="sidebar-description"> Documentos</span>
          </li>
          <li>
            <i class="fas fa-user"></i>
            <span class="sidebar-description"> Perfil</span>
          </li>
        </ul>
      </div>

      <ClientAreaMain v-if="view === 'main'"/>
      <OrderDetail v-if="view === 'order-details'"/>
    </div>
  </div>
</template>

<script>
import ClientAreaMain from "@/components/ClientArea/ClientAreaMain.vue";
import OrderDetail from "@/components/ClientArea/OrderDetail.vue";

export default {
  name: "ClientArea",
  components: {OrderDetail, ClientAreaMain},
  data() {
    return {
      view: 'main'
    }
  },
  created() {
    this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          if (toParams.order) {
            this.view = 'order-details';
          } else {
            this.view = 'main';
          }
        },
        {
          immediate: true
        }
    );
  }
}
</script>

<style scoped>
.container {
  padding: 0;
  width: 100%;
}

.buyer-area {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height: 100vh;
}

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  max-width: 80%;
  border-right: 1px solid var(--theme_color_1);
}

.sidebar ul {
  list-style: none;
}

.sidebar ul li {
  padding: 6px;
  color: var(--theme_color_1);
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: var(--theme_color_1);
  color: #ffffff !important;
  transition: .2s;
}

.sidebar ul li i {
  display: inline-block;
  width: 20px;
  text-align: center;
}

.sidebar .sidebar-description {
  display: none;
  margin-left: 5px;
}

.sidebar:hover .sidebar-description {
  display: inline-block;
}

.content {
  width: calc(100% - 30px);
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.content-title {
  display: block;
  width: 100%;
  color: var(--theme_color_1);
  font-size: 26px;
  margin-bottom: 20px;
}

</style>
