<template>
  <div class="offers-categories">
    <h1 class="categories-list-title">Categorias</h1>
    <ul class="offers-categories-list">
      <li class="offers-categories-list-item">Item</li>
      <li class="offers-categories-list-item">Item
        <ul class="offers-categories-sub-list">
          <li class="offers-categories-sub-list-item">Sub-item</li>
          <li class="offers-categories-sub-list-item">Sub-item</li>
          <li class="offers-categories-sub-list-item">Sub-item</li>
          <li class="offers-categories-sub-list-item">Sub-item</li>
        </ul>
      </li>
      <li class="offers-categories-list-item">Item</li>
      <li class="offers-categories-list-item">Item</li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "OffersCategories"
}
</script>

<style scoped>
.offers-categories {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  border-bottom: 1px solid var(--theme_color_3);
  background: var(--theme_color_3);
  color: #ffffff;
  padding: 20px;
  margin-top: 5%;
}

.categories-list-title{
  display: block;
  width: 100%;
  margin: 20px 0;
  font-size: 20px;
  text-transform: uppercase;
}

.offers-categories-list {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding: 0 20px;
  color: var(--theme_text_light);
  list-style: none;
}

.offers-categories-list-item {
  padding: 3px 6px;
  cursor: pointer;
}

.offers-categories-list-item::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--theme_color_2); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.offers-categories-sub-list {
  list-style: none;
}

.offers-categories-sub-list-item {
  padding: 3px 6px;
}

@media (min-width: 1024px) {
  .offers-categories{
    display: inline-block;
    margin-top: 0;
    flex-basis: 28%;
  }
}

</style>
