<template>
  <div class="main-footer container">
    <div class="footer-logo">
      <img src="@/assets/logo.png" alt="Logo">
    </div>
    <div class="footer-address footer-card">
      <span>QUADRA QUADRA 14, SN, LOTE21 - ALPHAVILE PAIVA</span>
      <span>NOVO GAMA - GO, CEP: 72863-529</span>
    </div>
    <div class="footer-contact footer-card">
      <span>(61) 3614-2546 | (61) 99952-4179</span>
      <span>clubedetirodamata@gmail.com</span>
    </div>
    <div class="footer-social">
      <a href="https://www.instagram.com/clubedetirodamata"
         target="_blank"
      >
        <i class="fab fa-instagram"></i> @clubedetirodamata
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.main-footer {
  text-align: center;
  background-color: var(--theme_color_3);
  color: var(--theme_text_light);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-social a {
  text-decoration: none;
  color: var(--theme_text_light);
}

.footer-logo {
  display: block;
  margin-bottom: 30px;
  flex-basis: 100%;
}

.footer-logo img {
  width: 100px;
}

.footer-card {
  flex-basis: 50%;
  padding: 20px;
}

.footer-card span {
  display: block;
  width: 100%;
}

.footer-address {
  text-align: right;
}

.footer-contact {
  text-align: left;
}
</style>
