<template>
  <div class="footer-wrap container">
    <div class="footer-wrap-card">
      <h1 class="footer-wrap-card-title">ADICIONADOS RECENTEMENTE</h1>
      <div class="footer-wrap-card-item" v-for="product in lastInserted" :key="product.id" @click="this.$router.push({ name: 'product', params: { product: product.slug }})">
        <img :src="product.image" :alt="product.title">
        <div class="footer-wrap-card-item-text">
          <p class="footer-wrap-card-item-desc">{{ product.description }}</p>
          <span class="footer-wrap-card-item-price">R$ {{ product.price }}</span>
        </div>
      </div>
    </div>

    <div class="footer-wrap-card">
      <h1 class="footer-wrap-card-title">MAIS VISTOS</h1>
      <div class="footer-wrap-card-item" v-for="product in moreViews" :key="product.id" @click="this.$router.push({ name: 'product', params: { product: product.slug }})">

          <img :src="product.image" :alt="product.title">
          <div class="footer-wrap-card-item-text">
            <p class="footer-wrap-card-item-desc">{{ product.description }}</p>
            <span class="footer-wrap-card-item-price">R$ {{ product.price }}</span>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterWrap",
  data() {
    return {
      lastInserted: [],
      moreViews: []
    }
  },
  created() {
    this.$store.commit('incrementRequests');
    fetch(`${process.env.VUE_APP_API_URL}/products/last-published`, {
      method: "GET",
      headers: {"Authorization": `Bearer ${process.env.VUE_APP_API_TOKEN}`}
    }).then(result => result.json())
        .then((result) => {
          if (result.products) {
            this.lastInserted = result.products
          }
          this.$store.commit('decrementRequests');
        }).catch((error) => {
      this.$store.commit('decrementRequests');
      console.log(error)
    });
    this.$store.commit('incrementRequests');
    fetch(`${process.env.VUE_APP_API_URL}/products/more-views`, {
      method: "GET",
      headers: {"Authorization": `Bearer ${process.env.VUE_APP_API_TOKEN}`}
    }).then(result => result.json())
        .then((result) => {
          if (result.products) {
            this.moreViews = result.products
          }
          this.$store.commit('decrementRequests');
        }).catch((error) => {
      this.$store.commit('decrementRequests');
      console.log(error)
    });
  }
}
</script>

<style scoped>
.footer-wrap {
  background-color: var(--theme_color_1);
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-wrap-card {
  flex-basis: 100%;
}

.footer-wrap-card-title {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
}

.footer-wrap-card-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.footer-wrap-card-item img {
  width: 20%;
  height: auto;
}

.footer-wrap-card-item-text {
  display: block;
  width: 78%;
  color: var(--theme_text_light)
}

.footer-wrap-card-item-price {
  color: var(--theme_color_2);
  display: block;
  margin-top: 5px;
}

@media (min-width: 585px) {
  .footer-wrap-card {
    flex-basis: 49%;
  }
}
</style>
