<template>
  <div class="container">
    <vue-basic-alert :duration="100" :close-in="5000" ref="alert"/>

    <div class="checkout-page">
      <h1 class="checkout-page-title">Finalizar pedido</h1>
      <form class="client-data" @submit.prevent>
        <div class="label">
          <label for="name" class="legend">Nome Completo</label>
          <input type="text" v-model="client.name" id="name">
        </div>

        <div class="label">
          <label for="cpf" class="legend">CPF</label>
          <input type="text" v-model="client.cpf" id="cpf">
        </div>

        <div class="label">
          <label for="cep" class="legend">CEP</label>
          <input type="text" v-model="client.cep" @change="loadCep" id="cep">
        </div>

        <div class="label">
          <label for="address" class="legend">Endereço</label>
          <input type="text" v-model="client.address" id="address">
        </div>

        <div class="label">
          <label for="number" class="legend">Nº</label>
          <input type="text" v-model="client.number" id="number">
        </div>

        <div class="label">
          <label for="district" class="legend">Bairro</label>
          <input type="text" v-model="client.district" id="district">
        </div>

        <div class="label">
          <label for="city" class="legend">Cidade</label>
          <input type="text" v-model="client.city" id="city">
        </div>

        <div class="label">
          <label for="state" class="legend">UF</label>
          <input type="text" v-model="client.state" id="state">
        </div>

        <div class="label">
          <label for="phone" class="legend">Telefone</label>
          <input type="text" v-model="client.phone" id="phone">
        </div>

        <div class="label">
          <label for="email" class="legend">Email</label>
          <input type="text" v-model="client.email" id="email">
        </div>

        <div class="label">
          <label for="birth-date" class="legend">Data de nascimento</label>
          <input type="date" v-model="client.birthDate" id="birth-date">
        </div>
      </form>

      <div class="order-details">
        <h2 class="order-details-title">Seu pedido</h2>
        <div class="order-details-item">
          <div class="product-item" v-for="product in $store.state.cart.products" :key="product.id">
            <p>{{ product.description }} x {{ product.quantity }}</p>
            <p>
              {{
                Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(product.price * product.quantity)
              }}
            </p>
          </div>
        </div>
        <div class="order-details-item">
          <p>Total</p>
          <p>
            <b>{{
                Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format($store.state.cart.subtotal ?? 0)
              }}</b>
          </p>
        </div>
        <div class="order-details-item">
          <p>Ao finalizar a compra o pedido será enviado a loja, para análise e
            liberação de toda a documentação necessária. É extremamente importante
            que as informações de contato estejam corretas e atualizadas.</p>
        </div>
        <button class="btn" @click="saveOrder">Finalizar</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueBasicAlert from 'vue-basic-alert'

export default {
  name: "Checkout",
  components: {
    VueBasicAlert
  },
  data() {
    return {
      client: {
        name: '',
        cpf: '',
        address: '',
        number: '',
        district: '',
        complement: '',
        city: '',
        state: '',
        cep: '',
        phone: '',
        email: '',
        birthDate: ''
      },
      paymentMethod: ''
    }
  },
  methods: {
    saveOrder() {
      const data = {};
      data.client = this.client;
      data.products = this.$store.state.cart.products;

      this.$store.commit('incrementRequests');
      fetch(`${process.env.VUE_APP_API_URL}/finish-order`, {
        method: 'POST',
        headers: {'Authorization': `Bearer ${process.env.VUE_APP_API_TOKEN}`},
        body: JSON.stringify(data)
      })
        .then(result => result.json())
        .then(result => {
          if (result.success) {
            this.$refs.alert.showAlert('success', result.success.message, 'Pedido cadastrado')
          } else if (result.errors) {
            this.$refs.alert.showAlert('error', result.errors.message, 'Erro')
          }
          this.$store.commit('decrementRequests');
        })
        .catch(error => {
          this.$refs.alert.showAlert('error', 'Não foi possível processar sua requisição', 'Erro')
          this.$store.commit('decrementRequests');
        });
    },
    loadCep() {
      this.client.address = '';
      this.client.number = '';
      this.client.district = '';
      this.client.complement = '';
      this.client.city = '';
      this.client.state = '';

      const cep = this.client.cep.replaceAll('-', '').replaceAll('.', '');
      this.$store.commit('incrementRequests');
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(result => result.json())
        .then((result) => {
          if (!result.erro) {
            this.client.address = result.logradouro;
            this.client.district = result.bairro;
            this.client.complement = result.complemento;
            this.client.city = result.localidade;
            this.client.state = result.uf;
          }
          this.$store.commit('decrementRequests');
        }).catch(error => {
        this.$store.commit('decrementRequests');
      });
    }
  }
}
</script>

<style scoped>
.checkout-page {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.checkout-page-title {
  flex-basis: 100%;
  font-size: 26px;
  color: var(--theme_color_1);
  margin-bottom: 20px;
}

.client-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.label {
  flex-basis: 100%;
  padding-bottom: 10px;
}

.label .legend {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.label :where(input, textarea, select) {
  width: 100%;
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.order-details {
  flex-basis: 100%;
  background-color: var(--theme_color_1);
  padding: 20px;
  color: var(--theme_color_light);
}

.order-details-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.order-details-item:not(:last-child) {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--theme_color_light);
}

.product-item:not(:last-child) {
  border-bottom: 1px solid var(--theme_color_light);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.order-details .btn {
  width: 100%;
  background-color: var(--theme_color_2);
}

.order-details .btn:hover {
  background-color: var(--theme_color_1);
}

@media (min-width: 1024px) {
  .client-data {
    flex-basis: 68%;
  }

  .order-details {
    flex-basis: 30%;
  }
}
</style>
