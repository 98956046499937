<template>
  <div class="products">
    <h1 class="products-title">PRODUTOS</h1>

    <div class="products-list">
      <div class="products-list-item" v-for="product in products" :key="product">
        <div>
          <img :src="product.image" :alt="product.desc" class="products-list-item-img">
          <p class="products-list-item-desc">{{ product.desc }}</p>
          <p class="products-list-item-price">{{ product.price }}</p>
        </div>
        <button class="products-list-item-button btn"
                @click="this.$router.push({ name: 'product', params: { product: product.slug }})">
          Ver mais
        </button>
      </div>
    </div>

    <Pager
        :current-page="pager.activePage"
        :per-page="pager.resultsPerPage"
        :total-pages="pager.totalPages"
        @pagechanged="changePage"
    />

  </div>
</template>

<script>
import Pager from "@/components/Pager";

export default {
  name: "ProductList",
  components: {Pager},

  data() {
    return {
      products: [],
      pager: {
        firstPage: 1,
        lastPage: 1,
        activePage: 1,
        totalPages: 1,
        resultsPerPage: 12,
        totalResults: 6
      }
    }
  },
  methods: {
    getProducts(category, page) {
      this.$store.commit('incrementRequests');
      fetch(`${process.env.VUE_APP_API_URL}/products/${category ? category : 'all'}/${page}`, {
        method: 'GET',
        headers: {"Authorization": `Bearer ${process.env.VUE_APP_API_TOKEN}`}
      }).then(response => response.json())
          .then((result) => {
            this.products = [];
            if (result.products) {
              result.products.forEach((element) => {
                this.products.push({
                  image: element.image,
                  desc: element.title,
                  price: `R$ ${element.price}`,
                  slug: element.slug
                })
              });
            }

            if (result.pager) {
              this.pager.firstPage = result.pager.first_page;
              this.pager.lastPage = result.pager.last_page;
              this.pager.activePage = result.pager.active_page;
              this.pager.totalPages = result.pager.total_pages;
              this.pager.resultsPerPage = result.pager.results_per_page;
              this.pager.totalResults = result.pager.total_results;
            }
            this.$store.commit('decrementRequests');
          })
          .catch(error => {
            this.$store.commit('decrementRequests');
            console.log('error', error)
          });
    },
    changePage(page){
      this.getProducts(this.$route.params.category, page);
    }
  },
  created() {
    this.getProducts(this.$route.params.category, this.pager.activePage);
    this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          this.getProducts(this.$route.params.category, this.pager.activePage);
        }
    );
  }

}
</script>

<style scoped>
.products {
  text-align: center;
}

.products-title {
  display: inline-block;
  margin-bottom: 10px;
  color: var(--theme_color_1);
  font-weight: normal;
  border-bottom: 5px solid var(--theme_color_1);
}

.products-desc {
  display: block;
  width: 100%;
  padding: 10px 50px;
  color: var(--theme_text_light);
  text-transform: uppercase;
  font-size: 16px;
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 0;
}

.products-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-basis: 98%;
  margin-bottom: 2%;
  margin-right: 1%;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #ffffff;
}

.products-list-item img {
  margin-bottom: 10px;
}

.products-list-item-desc {
  color: var(--theme_color_1);
  margin-bottom: 10px;
}

.products-list-item-price {
  color: var(--theme_color_1);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

@media (min-width: 585px) {
  .products-list-item {
    flex-basis: 49%;
  }
}

@media (min-width: 768px) {
  .products-list-item {
    flex-basis: 24%;
  }
}

@media (min-width: 1024px) {
  .products {
    width: 70%;
    display: inline-block;
  }
}

</style>
