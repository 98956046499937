import {createStore} from 'vuex'

export default createStore({
    state: {
        cart: {
            products: [],
            subtotal: 0
        },
        pendingRequests: 0
    },
    getters: {},
    mutations: {
        addProduct(state, data) {
            //verifica se já existe e soma a quantidade
            let product = state.cart.products.filter(product => product.id === data.id);
            if (product.length) {
                state.cart.products.map((product) => {
                    if (product.id === data.id) {
                        product.quantity++;
                        state.cart.subtotal = parseFloat(state.cart.subtotal) + parseFloat(data.price);
                    }
                });
            } else {
                state.cart.products.push({
                    id: data.id,
                    description: data.title,
                    quantity: 1,
                    price: data.price
                });
                state.cart.subtotal = parseFloat(state.cart.subtotal) + parseFloat(data.price);
            }
        },
        removeProduct(state, id) {
            const index = state.cart.products.findIndex(object => object.id === id);
            state.cart.subtotal = parseFloat(state.cart.subtotal) - (state.cart.products[index].price * state.cart.products[index].quantity)
            state.cart.products.splice(index, 1);
        },
        incrementRequests(state){
            state.pendingRequests++;
        },
        decrementRequests(state){
            state.pendingRequests--;
        }
    },
    actions: {}
})
